<template>
    <OTab :title="$t('Pivot Report')" v-if="dsPivotPublishedApplicationsList.data.length > 0">
        <iframe class="w-100 h-100" :src="'/pivot?LoadReportData=true&HideNav=true&Context=' + context.id"></iframe>
    </OTab>
</template>

<script setup>
    import { getOrCreateDataObject } from 'o365.vue.ts';
    import context from "o365.modules.Context.ts";
    import OTab from 'o365.vue.components.Tab.vue';
    
    const props = defineProps({
        appID: String
    });

    const dsPivotPublishedApplicationsList = getOrCreateDataObject({
        id: 'dsPivotPublishedApplicationsList',
        viewName: 'aviw_Pivots_Applications',
        maxRecords: 10,
        whereClause: "App_ID = '" + props.appID + "'",
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "App_ID", type: "string"}
        ]
    });
    dsPivotPublishedApplicationsList.load();
</script>